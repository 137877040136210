import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import { fetchUser, deleteUser, exportFile } from 'actions/User'
import UserView from 'components/pages/users/List'
import { pickBy, identity } from 'lodash'
import qs from 'query-string'
import history from 'utils/history'
import { message } from 'antd'

export function mapStateToProps(state) {
  const {
    isFetching,
    dataUser,
    metaUser,
  } = state.root.user

  return {
    isFetching,
    dataUser,
    metaUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchUser: bindActionCreators(fetchUser, dispatch),
  deleteUser: bindActionCreators(deleteUser, dispatch),
  exportFile: bindActionCreators(exportFile, dispatch),
})

const sortBy = [
  { id: 1, value: 'name', name: 'Name' },
  { id: 2, value: 'joined_date', name: 'Joined Date' },
  { id: 3, value: 'registered_number', name: 'Registered Number' },
]

const orderBy = [
  { id: 1, value: 'asc', name: 'Ascending' },
  { id: 2, value: 'desc', name: 'Descending' },
]

const statusBy = [
  { id: 1, value: 'active', name: 'Active' },
  { id: 2, value: 'inactive', name: 'Inactive' },
]

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('defaultStatus', 'setDefaultStatus', ''),
  withState('stateSearch', 'setStateSearch', {}),
  withState('sortBy', 'setSortBy', sortBy),
  withState('orderBy', 'setOrderBy', orderBy),
  withState('statusList', 'setStatusList', statusBy),
  withState('paramsFile', 'setParamsFile', ''),
  withState('isExport', 'setIsExport', false),
  withState('datePickerStatus', 'setDatePickerStatus', false),
  withHandlers({
    onLoad: props => (isSearch) => {
      const {
        page, search, order, sort, status, startDate, endDate,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        order: order || '',
        sort: sort || '',
        status: status || '',
        role: 'customer',
        start_date: startDate || '',
        end_date: endDate || '',
      }
      props.setParamsFile(`?${qs.stringify(pickBy(payload, identity))}`)
      props.fetchUser(`?${qs.stringify(pickBy(payload, identity))}`)
    },
    handleReport: props => () => {
      const { paramsFile, setIsExport } = props
      setIsExport(true)
      props.exportFile(paramsFile)
        .then((result) => {
          if (result) {
            setIsExport(false)
            history.push('/download')
          } else {
            message.error('Something when wrong')
          }
        }).catch((error) => {
          message.error(error)
          setIsExport(false)
        })
    },
  }),
  withHandlers({
    handleChangeSort: props => (val) => {
      const {
        onLoad, setStateSearch, stateSearch, setDatePickerStatus,
      } = props
      setStateSearch({
        ...stateSearch,
        sort: val,
      })
      if (val === 'joined_date') {
        setDatePickerStatus(true)
      } else {
        setDatePickerStatus(false)
      }
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeOrder: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        order: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleChangeDate: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      let start = ''
      let end = ''
      if (val.length === 2) {
        start = val[0].format('YYYY-MM-DD')
        end = val[1].format('YYYY-MM-DD')
      } else {
        start = ''
        end = ''
      }
      setStateSearch({
        ...stateSearch,
        startDate: start,
        endDate: end,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleDelete: props => (id) => {
      props.deleteUser(id)
    },
  }),
  lifecycle({
    componentDidMount() {
      const query = new URLSearchParams(this.props.location.search)
      const setStatus = query.get('status')
      this.props.setDefaultStatus(setStatus)
      this.props.setStateSearch({
        ...this.props.stateSearch,
        status: setStatus,
      })
      this.props.setParamsFile(`?role=customer${setStatus == null ? '' : `&status=${setStatus}`}`)
      this.props.updateSiteConfiguration('breadList', ['Home', 'Data Kepesertaan'])
      this.props.updateSiteConfiguration('titlePage', 'Member Management')
      this.props.fetchUser(`?role=customer${setStatus == null ? '' : `&status=${setStatus}`}`)
    },
  }),
)(UserView)

import PropTypes from 'prop-types'
import {
  Table, Select,
  Input, Tag,
  Tooltip, Icon,
} from 'antd'
import history from 'utils/history'
import Helper from 'utils/Helper'
import { isEmpty } from 'lodash'

const ClaimNormalView = ({
  meta, claimList, isLoadStatus, currentUser,
  isFetching, handlePage, statusList,
  handleSearch, handleChangeStatus, claimType,
  handleChangeClaimType,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (meta.per_page * (meta.current_page - 1)) + index + 1,
    },
    {
      title: 'Nama Pemohon',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Hubungan Pemohon',
      dataIndex: 'relation_type',
      key: 'relation_type',
      render: text => (!isEmpty(text) ? text.name : '-'),
    },
    {
      title: 'Nomor Peserta',
      dataIndex: 'registered_number',
      key: 'registered_number',
      render: text => text || '-',
    },
    {
      title: 'Jenis Klaim',
      dataIndex: 'claim_type',
      key: 'claim_type',
      render: text => (!isEmpty(text) ? text.name : '-'),
    },
    {
      title: 'Jumlah Klaim',
      dataIndex: 'claim_amount',
      key: 'claim_amount',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let tags
        if (text.name === 'fund transferred') {
          tags = <Tag color="green">{text.status_label || '-'}</Tag>
        } else if (text.name === 'rejected' || text.name === 'full rejection') {
          tags = <Tag color="red">{text.status_label || '-'}</Tag>
        } else {
          tags = <Tag>{text.status_label || '-'}</Tag>
        }
        return tags
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'action',
      render: (text, record) => (
        <div className="d-flex justify-content-center align-items-center">
          {(currentUser.permissions && currentUser.permissions.indexOf('manage-klaim-normal-read') > -1) && (
            <Tooltip title="Detail Klaim">
              <Icon type="eye" style={{ cursor: 'pointer' }} onClick={() => history.push(`/claim/claim-normal/${record?.id}/claim-request`)} />
            </Tooltip>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div className="pb-3">
        <div className="d-flex justify-content-start align-items-center btn-primary-wrapper">
          <div className="d-flex mr-2">
            <h6 className="mb-1 mr-2 align-self-center">Filter By: </h6>
            <Select
              placeholder="Status"
              style={{ width: 200 }}
              onChange={handleChangeStatus}
              loading={isLoadStatus}
              disabled={isLoadStatus}
              className="mr-2"
              allowClear
            >
              {(statusList || []).map(item => (
                <Select.Option key={item.name} value={item.name}>
                  <Tooltip placement="topLeft" title={item.status_label}>
                    {item.status_label}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder="Jenis Klaim"
              style={{ width: 200 }}
              onChange={handleChangeClaimType}
              loading={claimType.isFetching}
              disabled={claimType.isFetching}
              className="mr-2"
              allowClear
            >
              {(claimType.data || []).map(item => (
                <Select.Option key={item.id} value={item.id}>
                  <Tooltip placement="topLeft" title={item.name}>
                    {item.name}
                  </Tooltip>
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="mr-3">
            <Input.Search
              allowClear
              placeholder="Search..."
              onSearch={handleSearch}
              enterButton
            />
          </div>
          {/* <div className="mr-3">
            <Button type="primary">
              <Icon type="download" />
              Unduh Tabel Klaim
            </Button>
          </div> */}
        </div>
      </div>
      <Table
        rowKey="id"
        className="table-striped-rows"
        columns={columns}
        dataSource={claimList}
        loading={isFetching}
        pagination={{
          showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          total: meta ? meta.total_count : claimList.length,
          current: meta ? meta.current_page : 1,
          onChange: handlePage,
        }}
      />
    </div>
  )
}

ClaimNormalView.propTypes = {
  currentUser: PropTypes.object,
  isFetching: PropTypes.bool,
  isLoadStatus: PropTypes.bool,
  claimList: PropTypes.array,
  meta: PropTypes.object,
  statusList: PropTypes.array,
  claimType: PropTypes.object,
  handlePage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  handleChangeClaimType: PropTypes.func,
}

export default ClaimNormalView

import PropTypes from 'prop-types'
import { Table } from 'antd'
import Helper from 'utils/Helper'
import moment from 'moment'

const balanceInfoTable = ({
  isFetching, titleTable, dataTable,
}) => {
  const columns = [
    {
      title: 'No',
      dataIndex: '',
      key: 'No',
      render: (text, record, index) => (10 * (1 - 1)) + index + 1,
    },
    {
      title: 'Keterangan',
      dataIndex: 'Keterangan',
      key: 'Keterangan',
      render: text => text || '-',
    },
    {
      title: 'Tanggal',
      dataIndex: 'TanggalEntry',
      key: 'TanggalEntry',
      render: text => (text ? moment(text).format('DD MMM YY, HH:mm') : '-'),
    },
    {
      title: 'Kode Investasi',
      dataIndex: 'InvestCode',
      key: 'InvestCode',
      render: text => text || '-',
    },
    {
      title: 'Debit',
      dataIndex: 'Debit',
      key: 'Debit',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    {
      title: 'Kredit',
      dataIndex: 'Kredit',
      key: 'Kredit',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
    /* {
      title: 'Saldo Awal',
      dataIndex: 'SaldoAwal',
      key: 'SaldoAwal',
      render: text => (
        Helper.convertPrice(text)
      ),
    }, */
    {
      title: 'Saldo Akhir',
      dataIndex: 'SaldoAkhir',
      key: 'SaldoAkhir',
      render: text => (
        Helper.convertPrice(text)
      ),
    },
  ]

  return (
    <div>
      <h5 className="mb-4">{titleTable || '-'}</h5>
      <Table
        rowKey="ID"
        className="table-striped-rows"
        columns={columns}
        dataSource={dataTable}
        scroll={{ x: true }}
        loading={isFetching}
        pagination={{
          hideOnSinglePage: true,
        }}
      />
    </div>
  )
}

balanceInfoTable.propTypes = {
  isFetching: PropTypes.bool,
  titleTable: PropTypes.string,
  dataTable: PropTypes.array,
}

export default balanceInfoTable

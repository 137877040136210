import API from 'utils/API'
import {
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAILURE,
  NOTIFICATION_UPDATED,
  NOTIFICATION_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const notificationRequest = () => ({
  type: NOTIFICATION_REQUEST,
})

export const notificationSuccess = (data, meta) => ({
  type: NOTIFICATION_SUCCESS,
  data,
  meta,
})

export const notificationFailure = errorMessage => ({
  type: NOTIFICATION_FAILURE,
  errorMessage,
})

export const notificationUpdate = data => ({
  type: NOTIFICATION_UPDATED,
  data,
})

export const notificationDetail = data => ({
  type: NOTIFICATION_DETAIL_SUCCESS,
  data,
})

export const fetchNotification = params => (
  (dispatch) => {
    dispatch(notificationRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/announcements${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(notificationSuccess(data, meta))
        } else {
          dispatch(notificationFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(notificationFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const fetchDetailNotification = id => (
  (dispatch) => {
    dispatch(notificationRequest())
    const url = `${process.env.APP_CONFIG.api_url_content}/announcements/view/${id}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(notificationDetail(data))
        } else {
          dispatch(notificationFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(notificationFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const deleteNotification = id => (
  (dispatch) => {
    dispatch(notificationRequest())

    return API.delete(`${process.env.APP_CONFIG.api_url_content}/announcements/delete/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(notificationUpdate(data))
        } else {
          dispatch(notificationFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(notificationFailure(err.message)) // eslint-disable-line no-console
    })
  }
)

export const createNotification = payload => (
  () => new Promise((resolve, reject) => (
    API.post(`${process.env.APP_CONFIG.api_url_content}/announcements`, payload).then(
      (response) => {
        const { data, meta } = response?.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
        return response
      },
    ).catch((err) => {
      reject(err.message)
      return err
    })
  ))
)

export const updateNotification = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`${process.env.APP_CONFIG.api_url_content}/announcements/update/${id}`, payload).then(
      (response) => {
        if (response?.data?.meta) {
          resolve(response?.data)
        } else {
          reject(response.meta.message)
        }
        return response?.data
      },
    ).catch((err) => {
      reject(err.message)
      return err
    })
  ))
)

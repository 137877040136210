import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Avatar, Row,
  Col, Skeleton, Button,
  Popconfirm, Input, DatePicker,
  Form,
} from 'antd'
import moment from 'moment'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import history from 'utils/history'
import BalanceInfoTable from './balanceInfoTable/index'
import BenefitBeneficiary from './benefitBeneficiary/index'

const DetailUserPage = ({
  detailUser,
  benefitBeneficiaryList, handleAddBeneficiaryList, handleDeleteBeneficiaryList, benefitRelationshipList,
  handleResendEmail, handleResendEmailPin, isSubmit, isSubmitEdit, match, isDisable,
  currentUser, isEdit, handleChangeEdit, form, handleSubmit,
}) => {
  const lightBoxSetting = {
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showDownloadButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
      showFullscreenButton: false,
    },
  }
  const { getFieldDecorator, validateFields } = form
  return (
    <div className="pb-5">
      {detailUser?.isFetching
        ? [1, 2, 3].map(index => (
          <Card key={index} className="mb-3">
            <div>
              <Skeleton active />
            </div>
          </Card>
        ))
        : (
          <React.Fragment>
            <Form
              onSubmit={(e) => {
                e.preventDefault()
                validateFields((err, values) => {
                  if (!err) {
                    handleSubmit(values)
                  }
                })
              }}
            >
              <Card className="btn-primary-wrapper mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    {(currentUser.permissions && currentUser.permissions.indexOf('manage-member-management-write') > -1) && (
                    <Popconfirm
                      title="Are you sure you want to resend the Email?"
                      okText="Resend Email"
                      cancelText="No"
                      placement="topLeft"
                      onConfirm={() => handleResendEmail()}
                    >
                      <Button className="mr-3" style={{ borderColor: '#003db2', color: '#003db2' }} loading={isSubmit.resendEmail}>Resend Email</Button>
                    </Popconfirm>
                    )}
                    <Popconfirm
                      title="Are you sure you want to resend the Forgot Pin Email?"
                      okText="Resend Forgot Pin Email"
                      cancelText="No"
                      placement="topLeft"
                      onConfirm={() => handleResendEmailPin()}
                    >
                      <Button style={{ borderColor: '#003db2', color: '#003db2' }} loading={isSubmit?.resendPin}>Resend Email Forgot Pin</Button>
                    </Popconfirm>
                  </div>
                  <div className="d-flex">
                    <Button className="mr-2" style={{ borderColor: '#003db2', color: '#003db2' }} onClick={() => history.push(`/user/temanku/${match.params.id}`)}>Temanku</Button>
                    <Button className="mr-2" style={{ borderColor: '#003db2', color: '#003db2' }} onClick={() => history.push(`/user/pakde/${match.params.id}`)}>Downline</Button>
                    <Button type="primary" onClick={() => history.push(`/user/history-point/${match.params.id}`)}>Riwayat Point</Button>
                  </div>

                </div>
              </Card>
              <Card className="mb-3">
                <Row gutter={[24, 6]}>
                  <Col span={24}>
                    <h5 className="mb-3">Data Kepesertaan</h5>
                  </Col>
                  <div className="d-flex justify-content-end">
                    <Button className="ml-3" style={{ borderColor: '#003db2', color: '#003db2' }} onClick={handleChangeEdit}>{isEdit ? 'Batal' : 'Edit'}</Button>
                    {isEdit && <Button type="primary" className="ml-3" htmlType="submit" disabled={isDisable} loading={isSubmitEdit}>Submit</Button>}
                  </div>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Nomor Kepesertaan:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.registered_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Nomor KTP:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.identity_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Nama:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.name || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Tempat, Tanggal lahir:</p>
                    {isEdit
                      ? (
                        <React.Fragment>
                          <Row gutter={24}>
                            <Col span={10}>
                              <Form.Item className="mb-2">
                                {getFieldDecorator('birthplace', {
                                  initialValue: isEdit ? detailUser?.data?.birth_place : '',
                                  rules: [{ required: true }],
                                })(
                                  <Input className="col-12" />,
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={8}>
                              <Form.Item>
                                {getFieldDecorator('birthdate', {
                                  initialValue: isEdit && detailUser?.data?.dob ? moment(detailUser?.data?.dob, 'YYYY-MM-DD') : null,
                                  rules: [{ required: true, message: 'date and time is required' }],
                                })(
                                  <DatePicker style={{ padding: '0px' }} className="ml-0 col-12" format="YYYY-MM-DD" />,
                                )}
                              </Form.Item>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )
                      : (
                        <p style={{ fontSize: '16px' }}>
                          {detailUser?.data?.birth_place || '-'}
                          ,
                          {' '}
                          {detailUser?.data?.dob || '-'}
                        </p>
                      )
                  }
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Nomor Telepon:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.phone_number || '-'}</p>
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Email:</p>
                    {isEdit
                      ? (
                        <Form.Item className="mb-2">
                          {getFieldDecorator('email', {
                            initialValue: isEdit ? detailUser?.data?.email : '',
                            rules: [{ type: 'email', required: true, message: 'Email Tidak Valid' }],
                          })(
                            <Input className="col-6" />,
                          )}
                        </Form.Item>
                      ) : (
                        <p style={{ fontSize: '16px' }} className="text-primary font-weight-bold">
                          {detailUser.data?.email || '-'}
                        </p>
                      )
                  }
                  </Col>
                  <Col span={12}>
                    <p className="mr-3 mb-2 font-weight-bold">Lama Waktu Menabung</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.long_time_deposit || '-'}</p>
                  </Col>
                </Row>
              </Card>

              <BenefitBeneficiary
                isEdit={isEdit}
                getFieldDecorator={getFieldDecorator}
                beneficiaryList={benefitBeneficiaryList}
                benefitRelationshipList={benefitRelationshipList}
                handleAddBeneficiaryList={handleAddBeneficiaryList}
                handleDeleteBeneficiaryList={handleDeleteBeneficiaryList}
              />
            </Form>

            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-3">Data NPWP</h5>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">Foto KTP:</p>
                  {detailUser?.data?.identity_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailUser?.data?.identity_photo} alt="Identity Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Alamat KTP:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.ktp_addr || '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Kode Pos:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.postal_code || '-'}</p>
                  </Col>
                </Col>
              </Row>
            </Card>

            <Card className="mb-3">
              <Row gutter={[24, 6]}>
                <Col span={24}>
                  <h5 className="mb-3">Data Penghasilan</h5>
                </Col>
              </Row>
              <Row>
                <Col span={12} className="pb-3">
                  <p className="mr-3 mb-2 font-weight-bold">Foto NPWP:</p>
                  {detailUser?.data?.npwp_photo ? (
                    <SimpleReactLightbox>
                      <SRLWrapper options={lightBoxSetting}>
                        <Avatar shape="square" size={300} src={detailUser?.data?.npwp_photo} alt="NPWP Photo" style={{ cursor: 'pointer' }} />
                      </SRLWrapper>
                    </SimpleReactLightbox>
                  ) : (
                    <Avatar shape="square" size={300} src="/assets/default.jpg" alt="no img" />
                  )}
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Nama:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.identity && detailUser?.data?.name ? detailUser?.data?.name : '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Penghasilan:</p>
                    <p style={{ fontSize: '16px' }}>{detailUser?.data?.income && detailUser?.data?.income ? detailUser?.data?.income?.name : '-'}</p>
                  </Col>
                  <Col span={24}>
                    <p className="mr-3 mb-2 font-weight-bold">Sumber Penghasilan:</p>
                    <p>{detailUser?.data?.source_of_income || ''}</p>
                  </Col>
                </Col>
              </Row>
            </Card>
          </React.Fragment>
        )
      }

      <Card className="mb-3">
        <BalanceInfoTable
          isFetching={detailUser?.isFetching}
          titleTable="Laporan Posisi Dana 6 Bulan Berjalan"
          dataTable={detailUser.data?.rolling_transaction_history || []}
        />
      </Card>

      <Card>
        <BalanceInfoTable
          isFetching={detailUser?.isFetching}
          titleTable="Laporan Posisi Dana 6 Bulan Terakhir"
          dataTable={detailUser.data?.semester_transaction_history || []}
        />
      </Card>
    </div>
  )
}

DetailUserPage.propTypes = {
  detailUser: PropTypes.object,
  handleResendEmail: PropTypes.func,
  handleResendEmailPin: PropTypes.func,
  isSubmit: PropTypes.object,
  match: PropTypes.object,
  currentUser: PropTypes.object,
  isEdit: PropTypes.bool,
  handleChangeEdit: PropTypes.func,
  form: PropTypes.any,
  isSubmitEdit: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isDisable: PropTypes.bool,
  benefitBeneficiaryList: PropTypes.array,
  benefitRelationshipList: PropTypes.array,
  handleAddBeneficiaryList: PropTypes.func,
  handleDeleteBeneficiaryList: PropTypes.func,
}

export default Form.create({ name: 'userDetailForm' })(DetailUserPage)

import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form, Input,
  Button, DatePicker, Upload,
  Icon, Radio,
} from 'antd'
import { Row, Col } from 'react-bootstrap'
import moment from 'moment'
import history from 'utils/history'
import CKEditor from 'ckeditor4-react'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 5,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 12,
      span: 12,
    },
  },
}

const FormNotification = ({
  detailNotif, handleSubmit,
  form, isEdit, previewFile,
  handleChange, handleChangeEditor, isFetching,
}) => {
  const { getFieldDecorator, validateFields } = form
  const range = (start, end) => {
    const result = []
    // eslint-disable-next-line no-plusplus
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }
  const disabledDate = (current) => {
    const customDate = moment().add(0, 'days').format('YYYY-MM-DD HH:mm')
    return current && current < moment(customDate, 'YYYY-MM-DD HH:mm')
  }
  const disabledDateTime = () => ({
    disabledHours: () => range(0, moment().hour()),
    disabledMinutes: () => range(0, moment().minute()),
  })
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Penerima">
          {getFieldDecorator('send_all', {
            initialValue: isEdit ? detailNotif.send_all : '',
            rules: [{ required: true, message: 'Penerima peserta wajib dipilih' }],
          })(
            <Radio.Group>
              <Row>
                <Col md="10" span={8} className="mb-2">
                  <Radio value> Semua Peserta</Radio>
                </Col>
                <Col md="10" span={8}>
                  <Radio value={false} disabled={isEdit ? moment().format() > moment(detailNotif.date).format() : false}>
                    <span>Perserta Tertentu. Upload excel penerima push notification</span>
                    <br />
                    <Upload
                      disabled={isEdit ? moment().format() > moment(detailNotif.date).format() : false}
                      name="document"
                      listType="document"
                      beforeUpload={() => false}
                      onChange={e => handleChange(e)}
                      fileList={previewFile}
                      accept=".xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    >
                      <Button className="mt-2">
                        <Icon type="upload" />
                        {' '}
                        Attach file
                      </Button>
                    </Upload>
                  </Radio>
                </Col>
              </Row>
            </Radio.Group>,
          )}
        </Form.Item>
        <Form.Item label="Title Notifikasi">
          {getFieldDecorator('title', {
            initialValue: isEdit ? detailNotif.title : '',
            rules: [{ required: true, message: 'Title Notifikasi wajib diisi' }],
          })(
            <Input
              disabled={isEdit ? moment().format() > moment(detailNotif.date).format() : false}
              autoComplete="off"
              placeholder="Masukan Title Notifikasi"
            />,
          )}
        </Form.Item>
        <Form.Item label="Description Preview" extra={<small>Max Deskripsi Preview 240</small>}>
          {getFieldDecorator('content_preview', {
            initialValue: isEdit ? detailNotif.content_preview : '',
            rules: [{ required: true, message: 'Deskripsi Preview wajib diisi' }],
          })(
            <Input.TextArea
              autoComplete="off"
              rows={4}
              maxLength="240"
              disabled={isEdit ? moment().format() > moment(detailNotif.date).format() : false}
              placeholder="Masukan Deskripsi Preview"
            />,
          )}
        </Form.Item>
        <Form.Item label="Description">
          {isFetching
            ? <p>Loading Editor...</p>
            : (
              <CKEditor
                readOnly={isEdit ? moment().format() > moment(detailNotif.date).format() : false}
                name="content"
                data={isEdit ? detailNotif.content : ''}
                config={{
                  extraPlugins: 'justify,font,colorbutton,autogrow,liststyle',
                  toolbarGroups: [
                    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                    {
                      name: 'paragraph',
                      groups: ['list', 'indent', 'blocks', 'align', 'bidi'], // 'align' -> 'justify' plugin
                    },
                    { name: 'links' },
                    { name: 'insert' },
                    '/',
                    { name: 'styles' }, // 'font and fontsize' -> 'font' plugin
                    { name: 'colors' }, // 'colors' -> 'colorbutton' plugin
                    { name: 'tools' },
                    { name: 'others' },
                    { name: 'about' },
                    { name: 'document', groups: ['mode', 'document', 'doctools'] },
                  ],
                  stylesSet: [
                    { name: 'Italic Title', element: 'h2', styles: { 'font-style': 'italic' } },
                    { name: 'Special Container', element: 'div', styles: { background: '#eeeeee', border: '1px solid #cccccc', padding: '5px 10px' } },
                    { name: 'Marker: Yellow', element: 'span', styles: { 'background-color': 'Yellow' } },
                    { name: 'Marker: Blue', element: 'span', styles: { 'background-color': 'Blue', color: '#fff' } },
                    { name: 'Marker: Red', element: 'span', styles: { 'background-color': 'Red', color: '#fff' } },
                    { name: 'Img: Auto', element: 'img', styles: { width: '100% !important', height: 'auto !important' } },
                  ],
                  removeButtons: 'Anchor,Table',
                }}
                onChange={e => handleChangeEditor(e.editor.getData())}
              />
            )}
        </Form.Item>
        <Form.Item label="Date and Time">
          {getFieldDecorator('date', {
            initialValue: isEdit && detailNotif?.date ? moment(detailNotif?.date, 'YYYY-MM-DD HH:mm') : null,
            rules: [{ required: true, message: 'Tanggal dan waktu wajib diisi' }],
          })(
            <DatePicker
              disabled={isEdit ? moment()?.format() > moment(detailNotif.date)?.format() : false}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              disabledTime={disabledDateTime}
              disabledDate={disabledDate}
            />,
          )}
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button
            className="button-second-primary"
            onClick={() => history.push('/notification')}
          >
            Kembali
          </Button>
          <Button
            className={`button-primary ml-2 ${isEdit && moment().format() > moment(detailNotif.date).format() ? 'd-none' : ''}`}
            htmlType="submit"
          >
            {isEdit ? 'Simpan Perubahan' : 'Tambah Notifikasi'}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormNotification.propTypes = {
  detailNotif: PropTypes.object,
  isFetching: PropTypes.any,
  handleSubmit: PropTypes.func,
  form: PropTypes.any,
  isEdit: PropTypes.bool,
  previewFile: PropTypes.any,
  handleChange: PropTypes.func,
  handleChangeEditor: PropTypes.func,
  onChange: PropTypes.any,
}

export default Form.create({ name: 'notificationForm' })(FormNotification)

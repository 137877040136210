import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Form,
  Input, Button,
} from 'antd'
import history from 'utils/history'

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 2,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
}

const tailLayout = {
  wrapperCol: {
    xs: {
      offset: 24,
      span: 24,
    },
    sm: {
      offset: 9,
      span: 12,
    },
  },
}

const FormRequestView = ({
  form, isSubmit, handleSubmit,
}) => {
  const { getFieldDecorator, validateFields } = form
  return (
    <Card>
      <Form
        {...formItemLayout}
        onSubmit={(e) => {
          e.preventDefault()
          validateFields((err, values) => {
            if (!err) {
              handleSubmit(values)
            }
          })
        }}
      >
        <Form.Item label="Email">
          {getFieldDecorator('email', {
            rules: [{ required: true, type: 'email' }],
          })(
            <Input placeholder="Masukan Email Pemohon" />,
          )}
        </Form.Item>
        <Form.Item label="Name">
          {getFieldDecorator('name', {
            rules: [{ required: true, message: 'Nama pemohon wajib diisi' }],
          })(
            <Input
              type="text"
              placeholder="Masukan Nama Pemohon"
            />,
          )}
        </Form.Item>
        <Form.Item {...tailLayout} className="btn-primary-wrapper">
          <Button
            className="button-second-primary mr-2"
            onClick={() => history.push('/claim/claim-by-admin')}
          >
            Kembali
          </Button>
          <Button
            loading={isSubmit}
            type="primary"
            className="button-primary"
            htmlType="submit"
          >
            Submit Form
          </Button>
        </Form.Item>
      </Form>
    </Card>
  )
}

FormRequestView.propTypes = {
  isEdit: PropTypes.bool,
  isSubmit: PropTypes.bool,
  form: PropTypes.any,
  handleSubmit: PropTypes.func,
}

export default Form.create({ name: 'formRequest' })(FormRequestView)

import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import {
  fetchClaim, fetchClaimStatus, fetchClaimType, deleteClaim,
} from 'actions/Claim'
import ClaimNormalView from 'components/pages/claim/claimNormal/List'
import { pickBy, identity, isNull } from 'lodash'
import qs from 'query-string'
import { message } from 'antd'

// export function mapStateToProps(state) {}
export function mapStateToProps(state) {
  const { currentUser } = state.root.auth

  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  fetchClaim: bindActionCreators(fetchClaim, dispatch),
  fetchClaimStatus: bindActionCreators(fetchClaimStatus, dispatch),
  fetchClaimType: bindActionCreators(fetchClaimType, dispatch),
  deleteClaim: bindActionCreators(deleteClaim, dispatch),

})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('stateSearch', 'setStateSearch', {}),
  withState('claimList', 'setClaimList', []),
  withState('isFetching', 'setIsFetching', true),
  withState('statusList', 'setStatusList', []),
  withState('isLoadStatus', 'setIsLoadStatus', true),
  withState('claimType', 'setClaimType', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('meta', 'setMeta', {}),
  withHandlers({
    onSetStateSearch: props => () => {
      const { setStateSearch, stateSearch } = props
      const parseQS = qs.parse(window.location.search)
      setStateSearch({
        ...stateSearch,
        ...parseQS,
      })
    },
    onChangeLinkPage: props => () => {
      window.history.replaceState(null, undefined, `?${qs.stringify(pickBy(props.stateSearch, identity))}`)
    },
  }),
  withHandlers({

    onLoad: props => (isSearch) => {
      const {
        page, search, status, claimType,
      } = props.stateSearch
      const payload = {
        page: isSearch ? '' : page,
        search: search || '',
        status: status || '',
        claim_type: claimType || '',
      }
      props.setIsFetching(true)
      props.fetchClaim(`?${qs.stringify(pickBy(payload, identity))}`)
        .then((result) => {
          const { data, meta } = result
          props.setIsFetching(false)
          props.setMeta(meta)
          if (isNull(data)) {
            props.setClaimList([])
          } else {
            props.setClaimList(data)
          }
        })
        .catch((error) => {
          message.error(error.message)
        })
    },
  }),
  withHandlers({
    handleSearch: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        search: val,
      })
      setTimeout(() => {
        onLoad(true)
      }, 300)
    },
    handlePage: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        page: val,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleChangeStatus: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        status: val,
        page: 1,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleChangeClaimType: props => (val) => {
      const { onLoad, setStateSearch, stateSearch } = props
      setStateSearch({
        ...stateSearch,
        claimType: val,
        page: 1,
      })
      setTimeout(() => {
        onLoad()
      }, 300)
    },
    handleDelete: props => (id) => {
      const { onLoad } = props
      props.deleteClaim(id)
        .then(() => {
          message.success('success delete')
          setTimeout(() => {
            onLoad()
          }, 1000)
        })
    },
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.onSetStateSearch()
      this.props.onLoad()
      this.props.fetchClaimStatus()
        .then((result) => {
          this.props.setIsLoadStatus(false)
          this.props.setStatusList(result)
        })
        .catch((error) => {
          this.props.setIsLoadStatus(false)
          message.error(error.message)
        })

      this.props.fetchClaimType()
        .then((result) => {
          this.props.setClaimType({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
        })
        .catch((error) => {
          this.props.setClaimType({
            data: [],
            meta: {},
            isFetching: false,
          })
          message.error(error.message)
        })
    },
  }),
)(ClaimNormalView)

import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { message } from 'antd'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchDetailClaim, updateStatusClaim, checkDocumentValidateClaim, uploadBukitTransfer, exportDoc,
} from 'actions/Claim'
import DetailCustomerClaimDeath from 'components/pages/claim/claimMeninggalDunia/detail'
import Helper from 'utils/Helper'

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailClaim: bindActionCreators(fetchDetailClaim, dispatch),
  updateStatusClaim: bindActionCreators(updateStatusClaim, dispatch),
  checkDocumentValidateClaim: bindActionCreators(checkDocumentValidateClaim, dispatch),
  uploadBukitTransfer: bindActionCreators(uploadBukitTransfer, dispatch),
  exportDoc: bindActionCreators(exportDoc, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('detailClaim', 'setDetailClaim', {
    data: [],
    meta: {},
    isFetching: true,
  }),
  withState('imageModal', 'setImageModal', false),
  withState('imgUrl', 'setImgUrl', ''),
  withState('isSubmit', 'setIsSubmit', false),
  withState('isFetchingData', 'setIsFetchingData', false),
  withState('documents', 'setDocuments', []),
  withState('scrollToTop', 'setScrollToTop', false),
  withState('rejectNotes', 'setRejectNotes', ''),
  withState('reason', 'setReason', {}),
  withState('rejectModal', 'setRejectModal', false),
  withState('uploadModal', 'setUploadModal', false),
  withState('isUploading', 'setIsUploading', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('fileName', 'setFileName', ''),
  withState('isExport', 'setIsExport', false),
  withHandlers({
    handleScrollToTop: props => () => {
      props.setScrollToTop(true)
      setTimeout(() => {
        props.setScrollToTop(false)
      }, 5000)
    },
  }),
  withHandlers({
    fetchDetail: props => () => {
      const { match, setIsFetchingData } = props
      setIsFetchingData(true)
      props.fetchDetailClaim(match.params.id)
        .then((result) => {
          // const { data, meta } = result
          const newDoc = []
          setIsFetchingData(false)
          props.setDetailClaim({
            data: result.data,
            meta: result.meta,
            isFetching: false,
          })
          result.data.documents.map((item) => {
            if (item.status === 'approved') {
              newDoc.push({ document_id: item.id, is_approved: true, reason: '' })
            } else {
              newDoc.push({ document_id: item.id, is_approved: false, reason: '' })
            }
          })
          if (newDoc) {
            props.setDocuments(newDoc)
          }
        })
        .catch((error) => {
          setIsFetchingData(false)
          message.error(error)
        })
    },
  }),
  withHandlers({
    handleDownloadClaimDoc: props => (name) => {
      const { paramsFile, match, setIsExport } = props
      setIsExport(true)
      props.exportDoc(match.params.id)
      .then((result) => {
        if (result) {
          const FileSaver = require('file-saver') // eslint-disable-line global-require
          const blob = new Blob([result.data], { type: "application/pdf" })
          FileSaver.saveAs(blob, `claim_documents_${name}.pdf`)
          setIsExport(false)
        } else {
          message.error('Something when wrong')
        }
      }).catch((error) => {
        message.error(error)
        setIsExport(false)
      })
    },
    handleChangeFile: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)

      if (fileList.length > 0) {
        props.setFileName(info.file.name)
        return Helper.getBase64(info.file, file => props.setPreviewFile(file))
      }
      return false
    },
    handleUpload: props => () => {
      const {
        setIsUploading, uploadBukitTransfer, fetchDetail,
        match, setPreviewFile, setUploadModal
      } = props
      const payload = {
        document: props.previewFile
      }
      setIsUploading(true)
      uploadBukitTransfer(payload, match.params.id).then(() => {
        fetchDetail()
        setPreviewFile('')
        setUploadModal(!props.uploadModal)
        setIsUploading(false)
        message.success('File has been uploaded').then(() => (
          setUploadModal(!props.uploadModal)
        ))
      }).catch((err) => {
        setIsUploading(false)
        message.error(err)
        setUploadModal(!props.uploadModal)
      })
    },
    checkStatus: props => (id) => {
      let index
      index = props.documents.find(item => item.document_id === id)
      if (index) {
        return index.is_approved
      }
    },
    handleChangeNote: props => (e) => {
      const { reason, setReason, documents, setDocuments } = props
      const val = e.target.value

      const currentDoc = documents
      let index
      index = currentDoc.findIndex(item => item.document_id === e.target.id)
      currentDoc[index].reason = val
      setDocuments(currentDoc)
    },
    handleDownload: props => (link) => {
      var url = window.open(link, "_blank")
      url.location
    },
    toggleImageModal: props => (val) => {
      props.setImgUrl(val)
      props.setImageModal(!props.imageModal)
    },
    toggleRejectModal: props => () => {
      props.setRejectModal(!props.rejectModal)
    },
    toggleUploadModal: props => () => {
      props.setUploadModal(!props.uploadModal)
    },
    onChangeDoc: props => (e) => {
      const { documents, setDocuments } = props
      const checkDoc = documents
      let index
      if (e.target.checked === true) {
        index = checkDoc.findIndex(item => item.document_id === e.target.name)
        checkDoc[index].is_approved = true
      } else {
        index = checkDoc.findIndex(item => item.document_id === e.target.name)
        checkDoc[index].is_approved = false
      }
      setDocuments(checkDoc)
    },
    handleChangeRejectNotes: props => (e) => {
      props.setRejectNotes(e.target.value)
    },
    handleFullReject: props => () => {
      const {
        setIsSubmit, updateStatusClaim, setRejectModal,
        match, rejectNotes, fetchDetail
      } = props
      if (rejectNotes === '') {
        message.error('Claim reject reason cant be empty')
        return
      }
      const payload = {
        status: "full rejection",
        notes: rejectNotes
      }

      setIsSubmit(true)
      updateStatusClaim(match.params.id, payload)
      .then(result => {
        message.success("Claim request has been Rejected")
        setRejectModal(!props.rejectModal)
        setIsSubmit(false)
        fetchDetail()
      })
     .catch((error) => {
        setIsSubmit(false)
        message.error(error)
      })
    },
    onSubmitRequest: props => () => {
      const {
        documents, match, fetchDetail,
        setIsSubmit,
      } = props
      setIsSubmit(true)
      let isNotComplete = false

      documents.forEach(item => {
        if (!item.is_approved && item.reason === '') {
          isNotComplete = true
          return
        }
      })

      if (isNotComplete) {
        message.error('Please complete the reason on the rejected document')
        setIsSubmit(false)
      } else {
        props.checkDocumentValidateClaim(match?.params?.id, { document_validation: documents })
          .then((result) => {
            message.success(result)
            setIsSubmit(false)
            fetchDetail()
          })
          .catch((error) => {
            setIsSubmit(false)
            message.error(error)
          })
      }
    },
    handleUpdateStatusClaim: props => (val) => {
      const statusValue = {
        status: val,
      }
      props.setIsSubmit(true)
      props.updateStatusClaim(props.match.params.id, statusValue)
        .then((result) => {
          message.success(result)
          props.setIsSubmit(false)
          props.fetchDetail()
          props.handleScrollToTop()
        })
        .catch((error) => {
          props.setIsSubmit(false)
          message.error(error)
        })
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.updateSiteConfiguration('breadList', ['Beranda', 'Pengajuan Klaim', 'Administrasi'])
      this.props.updateSiteConfiguration('titlePage', 'Detail Permohonan Klaim')
      this.props.fetchDetail()
    },
  }),
)(DetailCustomerClaimDeath)

import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration } from 'actions/Site'
import {
  fetchDetailUser, fetchGetBenefitRelationshipList,
  resendEmail, updateDateDetail,
} from 'actions/User'
import DetailUserView from 'components/pages/users/detail'
import { message } from 'antd'
import moment from 'moment'
import history from 'utils/history'

export function mapStateToProps(state) {
  const { currentUser } = state.root.auth
  return {
    currentUser,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  fetchDetailUser: bindActionCreators(fetchDetailUser, dispatch),
  fetchGetBenefitRelationshipList: bindActionCreators(fetchGetBenefitRelationshipList, dispatch),
  resendEmail: bindActionCreators(resendEmail, dispatch),
  updateDateDetail: bindActionCreators(updateDateDetail, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('isEdit', 'setIsEdit', false),
  withState('isSubmit', 'setIsSubmit', {
    resendEmail: false,
    resendPin: false,
  }),
  withState('isSubmitEdit', 'setIsSubmitEdit', false),
  withState('isDisable', 'setIsDisable', false),
  withState('detailUser', 'setDetailUser', {
    isFetching: false,
    data: {},
  }),
  withState('benefitRelationshipList', 'setBenefitRelationship', []),
  withState('benefitBeneficiaryList', 'setBenefitBeneficiaryList', []),
  withHandlers({
    handleResend: props => (params) => {
      props.resendEmail(params)
        .then(() => {
          message.success('Email was successfully resent')
          props.setIsSubmit({
            resendEmail: false,
            resendPin: false,
          })
        })
        .catch(() => {
          props.setIsSubmit({
            resendEmail: false,
            resendPin: false,
          })
        })
    },
  }),
  withHandlers({
    handleAddBeneficiaryList: props => () => {
      const newItem = {
        name: '',
        dob: '',
        relationship_id: '',
      }
      props.setBenefitBeneficiaryList(prevArray => [...prevArray, newItem])
    },
    handleDeleteBeneficiaryList: props => (index) => {
      props.setBenefitBeneficiaryList((prevArrays) => {
        const newArray = [...prevArrays]
        newArray.splice(index, 1)
        return newArray
      })
    },
    handleChangeEdit: props => () => {
      const { isEdit, setIsEdit } = props
      setIsEdit(!isEdit)
    },
    handleGetBenefitRelationshipList: props => () => {
      const { setBenefitRelationship } = props
      props.fetchGetBenefitRelationshipList()
        .then((response) => {
          if (response?.meta?.code === 200) {
            setBenefitRelationship(response?.data)
          }
        })
        .catch(() => {
          setBenefitRelationship([{
            id: '',
            name: '',
          }])
        })
    },
    handleGetDetailUser: props => (params) => {
      const { setDetailUser, setBenefitBeneficiaryList } = props
      setDetailUser({ isFetching: true, data: {} })
      props.fetchDetailUser(params)
        .then((response) => {
          if (response?.meta?.code === 200) {
            setDetailUser({
              isFetching: false,
              data: response?.data,
            })
            props.setBenefitBeneficiaryList(response?.data?.benefit_recipients ? response?.data?.benefit_recipients : [
              {
                name: '',
                dob: '',
                relationship_id: '',
              },
            ])
          }
        }).catch(() => {
          setDetailUser({
            isFetching: false,
            data: {},
          })
          setBenefitBeneficiaryList([
            {
              name: '',
              dob: '',
              relationship_id: '',
            },
          ])
        })
    },
    handleSubmit: props => (values) => {
      const {
        match, setIsSubmitEdit, setIsDisable, setIsEdit,
      } = props
      setIsSubmitEdit(true)
      const beneficiaryList = values?.benefit_beneficiary_list.map(item => ({
        ...item,
        dob: moment(item?.dob)?.format('YYYY-MM-DD'),
      }))
      const payload = {
        email: values?.email || '',
        birthdate: moment(values?.birthdate)?.format('YYYY-MM-DD') || '',
        birthplace: values?.birthplace || '',
        benefit_recipients: beneficiaryList,
      }
      props.updateDateDetail(match?.params?.id, payload)
        .then(() => {
          setIsSubmitEdit(false)
          setIsDisable(false)
          setIsEdit(false)
          message.success('Berhasil Mengupdate Data Kepesertaan')
          history.push('/user')
        }).catch((err) => {
          setIsDisable(false)
          setIsEdit(false)
          setIsSubmitEdit(false)
          message.error(err)
        })
    },
    handleResendEmail: props => () => {
      props.setIsSubmit({ resendEmail: true })
      props.handleResend({
        event: 'approve-user-email',
        user_id: props.match.params.id,
      })
    },
    handleResendEmailPin: props => () => {
      props.setIsSubmit({ resendPin: true })
      props.handleResend({
        event: 'forgot-pin-email',
        user_id: props.match.params.id,
      })
    },
  }),
  lifecycle({
    componentDidMount() {
      const { match } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Data Kepesertaan', 'Detail'])
      this.props.updateSiteConfiguration('titlePage', 'Detail Kepesertaan')
      if (match?.params?.id) {
        this.props.handleGetDetailUser(match?.params?.id)
      }
      this.props.handleGetBenefitRelationshipList()
    },
  }),
)(DetailUserView)

import API from 'utils/API'
import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  USER_UPDATED,
  USER_DETAIL_SUCCESS,
} from 'constants/ActionTypes'

export const userRequest = () => ({
  type: USER_REQUEST,
})

export const userSuccess = (data, meta) => ({
  type: USER_SUCCESS,
  data,
  meta,
})

export const userFailure = errorMessage => ({
  type: USER_FAILURE,
  errorMessage,
})

export const userUpdate = data => ({
  type: USER_UPDATED,
  data,
})

export const userDetail = data => ({
  type: USER_DETAIL_SUCCESS,
  data,
})

export const fetchUser = params => (
  (dispatch) => {
    dispatch(userRequest())
    const url = `/user/accounts/list${params || ''}`
    return API.get(url).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(userSuccess(data, meta))
        } else {
          dispatch(userFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(userFailure(err.message))
    })
  }
)

export const fetchDetailUser = id => (
  () => new Promise((resolve, reject) => (
    API.get(`/auth/customer/fetch-user-personal-detail/${id || ''}`).then(
      (response) => {
        const { data } = response
        if (data?.meta?.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
        return response
      },
    ).catch((err) => {
      reject(err.message)
      return err
    })
  ))
)

export const fetchGetBenefitRelationshipList = () => (
  () => new Promise((resolve, reject) => (
    API.get('/list-form/relationship-type').then(
      (response) => {
        const { data } = response
        if (data?.meta?.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
        return response
      },
    ).catch((err) => {
      reject(err.message)
      return err
    })
  ))
)

export const deleteUser = id => (
  (dispatch) => {
    dispatch(userRequest())
    return API.delete(`/user/${id}`).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          dispatch(userUpdate(data))
        } else {
          dispatch(userFailure(meta.message))
        }
      },
    ).catch((err) => {
      dispatch(userFailure(err.message))
    })
  }
)

export const createUser = payload => (
  () => new Promise((resolve, reject) => (
    API.post('/user', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateUser = (payload, id) => (
  () => new Promise((resolve, reject) => (
    API.put(`/user/${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const resendEmail = payload => (
  () => new Promise((resolve, reject) => (
    API.post('/email/resend', payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const updateDateDetail = (id, payload) => (
  () => new Promise((resolve, reject) => (
    API.put(`/user/update-partial?account_id=${id}`, payload).then(
      (response) => {
        const { data, meta } = response.data
        if (meta.status) {
          resolve(data)
        } else {
          reject(meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchHistoryPoint = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`/user/point/history/${id}${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const exportFile = params => (
  () => new Promise((resolve, reject) => (
    API.get(`/user/accounts/list/export${params || ''}`).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const exportFileHistoryPoint = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`/user/point/history/${id}/export${params || ''}`).then(
      (response) => {
        resolve(response)
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchPakDeDownlineList = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`/cms/pak-d?upline_id=${id}${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

export const fetchTemankuDownlineList = (id, params) => (
  () => new Promise((resolve, reject) => (
    API.get(`/cms/my-friend?upline_id=${id}${params || ''}`).then(
      (response) => {
        const { data } = response
        if (data.meta.status) {
          resolve(data)
        } else {
          reject(data.meta.message)
        }
      },
    ).catch((err) => {
      reject(err.message)
    })
  ))
)

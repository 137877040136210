/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import {
  Card, Row, Col, Form, Input, Icon, Select, DatePicker,
} from 'antd'
import moment from 'moment'

const BenefitBeneficiaryList = ({
  getFieldDecorator, isEdit, beneficiaryList, benefitRelationshipList,
  handleAddBeneficiaryList, handleDeleteBeneficiaryList,
}) => {
  const disableFutureDates = current => current && current > moment().endOf('day')
  return (
    <div className="mb-3">
      <Card>
        <h5 className="mb-3">Data Penerima Manfaat</h5>
        {(beneficiaryList || []).map((item, index) => (
          <div key={{ index }}>

            <div className="w-100 mb-3 display-flex justify-content-between align-items-center">
              <div>
                <h6 className="mb-0" style={{ fontSize: '16px', fontWeight: 600, color: '#004fa4' }}>
                  {`Penerima Manfaat ${index + 1}`}
                </h6>
              </div>

              {isEdit && (
              <div className={`button-danger ${index === 0 ? 'd-none' : ''}`} onClick={() => handleDeleteBeneficiaryList(index)}>
                Hapus
              </div>
              )}
            </div>

            <Row gutter={[24, 16]}>
              <Col span={12}>
                <Form.Item>
                  <h6 className="fs-14 mb-2">Nama</h6>
                  {isEdit ? (
                    <>
                      {getFieldDecorator(`benefit_beneficiary_list[${index}].name`, {
                        initialValue: isEdit ? item.name : '',
                        rules: [
                          { required: true, message: 'Nama lengkap wajib diisi' },
                          { min: 2, message: 'Nama harus terdiri dari minimal 2 karakter' },
                          { max: 100, message: 'Nama maksimal terdiri 100 karakter' },
                        ],
                      })(
                        <Input
                          autoComplete="off"
                          placeholder="Masukan Nama lengkap"
                          onKeyPress={(event) => {
                            if (!/^[A-Za-z\s]$/.test(event.key)) {
                              event.preventDefault()
                            }
                          }}
                        />,
                      )}
                    </>
                  ) : <div className="fs-14 line-height-normal">{item.name || '-'}</div>}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <h6 className="fs-14 mb-2">Hubungan</h6>
                  {isEdit ? (
                    <>
                      {getFieldDecorator(`benefit_beneficiary_list[${index}].relationship_id`, {
                        initialValue: isEdit ? item.relationship_id : null,
                        rules: [{ required: true, message: 'Hubungan penerima manfaat wajib dipilih' }],
                      })(
                        <Select
                          placeholder="Pilih Hubungan"
                        >
                          {benefitRelationshipList.map(item => <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>)}
                        </Select>,
                      )}
                    </>
                  ) : <div className="fs-14 line-height-normal">{item.relationship_type?.name || '-'}</div>}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item>
                  <h6 className="fs-14 mb-2">Tanggal Lahir</h6>
                  {isEdit ? (
                    <>
                      {getFieldDecorator(`benefit_beneficiary_list[${index}].dob`, {
                        initialValue: isEdit && item?.dob ? moment(`${item.dob}`, 'YYYY-MM-DD') : null,
                        rules: [{ required: true, message: 'Tanggal lahir wajib diisi' }],
                      })(
                        <DatePicker
                          disabledDate={disableFutureDates}
                          placeholder="Pilih Tanggal"
                          format="YYYY-MM-DD"
                        />,
                      )}
                    </>
                  ) : <div className="fs-14 line-height-normal">{moment(item.dob)?.format('YYYY-MM-DD') || '-'}</div>}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ))}

        <Row className={`mt-2 ${isEdit ? '' : 'd-none'}`}>
          {beneficiaryList.length < 4 && (
            <Col span={24}>
              <div className="display-flex justify-content-center">
                <div onClick={() => handleAddBeneficiaryList()} style={{ fontWeight: 600, color: '#004fa4', cursor: 'pointer' }}>
                  <Icon type="plus-circle" />
                  <span className="ml-2">Tambah Penerima Manfaat</span>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  )
}


BenefitBeneficiaryList.propTypes = {
  isEdit: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  beneficiaryList: PropTypes.array,
  benefitRelationshipList: PropTypes.array,
  handleAddBeneficiaryList: PropTypes.func,
  handleDeleteBeneficiaryList: PropTypes.func,
}

export default BenefitBeneficiaryList

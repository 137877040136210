/* eslint-disable consistent-return */
import { connect } from 'react-redux'
import {
  compose, lifecycle, withHandlers, withState,
} from 'recompose'
import { bindActionCreators } from 'redux'
import { updateSiteConfiguration, updateSiteConfigs } from 'actions/Site'
import { fetchDetailNotification, createNotification, updateNotification } from 'actions/Notification'
import FormNotificationView from 'components/pages/notifications/Form'
import Helper from 'utils/Helper'
import moment from 'moment'
import { message } from 'antd'
import history from 'utils/history'

export function mapStateToProps(state) {
  const {
    isFetching,
    detailNotif,
  } = state.root.notification
  return {
    isFetching,
    detailNotif,
  }
}

const mapDispatchToProps = dispatch => ({
  updateSiteConfiguration: bindActionCreators(updateSiteConfiguration, dispatch),
  updateSiteConfigs: bindActionCreators(updateSiteConfigs, dispatch),
  fetchDetailNotification: bindActionCreators(fetchDetailNotification, dispatch),
  createNotification: bindActionCreators(createNotification, dispatch),
  updateNotification: bindActionCreators(updateNotification, dispatch),
})

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withState('descriptionEditor', 'setDescriptionEditor', ''),
  withState('isEdit', 'setIsEdit', false),
  withState('previewFile', 'setPreviewFile', ''),
  withState('file', 'setFile', ''),
  withHandlers({
    handleChangeEditor: props => (values) => {
      props.setDescriptionEditor(values)
    },
    handleChange: props => (info) => {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      props.setPreviewFile(fileList)
      if (fileList.length > 0) {
        Helper.getBase64(info.file, file => props.setFile(file))
      } else {
        props.setFile('')
      }
    },
    handleSubmit: props => (values) => {
      const {
        match, file, descriptionEditor,
      } = props
      const payload = {
        title: values?.title,
        content_preview: values?.content_preview,
        content: descriptionEditor,
        date: moment(values?.date).format(),
        send_all: values?.send_all,
        send_now: moment().format() > moment(values?.date).format(),
        recipients: '',
      }
      if (descriptionEditor === '') {
        message.error('Deskripsi Notifikasi wajib diisi')
        return
      }
      if (match?.params?.id) {
        if (!values?.send_all) {
          if (file === '') {
            message.error('File Daftar Peserta Wajib diisi')
            return
          }
          payload.recipients = file
        }
        props.updateNotification(payload, match?.params?.id)
          .then(() => {
            message.success('Notifikasi berhasil di Update')
            history.push('/notification')
          })
          .catch((err) => {
            throw err
          })
      } else {
        if (!values.send_all) {
          if (file === '') {
            message.error('Mohon masukan file Daftar Peserta')
            return
          }
          payload.recipients = file
        }
        props.createNotification(payload)
          .then(() => {
            message.success('Notifikasi berhasil di tambahkan')
            history.push('/notification')
          })
          .catch((err) => {
            throw err
          })
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const {
        match, setIsEdit,
      } = this.props
      this.props.updateSiteConfiguration('breadList', ['Home', 'Notification', 'Form'])
      this.props.updateSiteConfigs({
        titlePage: 'Notification Form',
      })
      if (match?.params?.id) {
        this.props.fetchDetailNotification(match?.params?.id)
        setIsEdit(true)
      }
    },
  }),
)(FormNotificationView)
